import Cookies from 'js-cookie'

// token
const tokenCookies = {
    set(token) { Cookies.set('Authorization', token) },
    get: () => Cookies.get('Authorization'),
    remove: () => Cookies.remove('Authorization')
}

// menu 菜单权限
const menuCookies = {
    set(menu) { localStorage.setItem('menu', menu) },
    get: () => localStorage.getItem('menu'),
    remove: () => localStorage.removeItem('menu')
}
// submenu 界面权限
const submenuCookies = {
    set(submenu) {localStorage.setItem('submenu', submenu)},
    get: () => localStorage.getItem('submenu'),
    remove: () => localStorage.removeItem('submenu')
}
// button 按钮权限
const buttonCookies = {
    set(button) { localStorage.setItem('button', button) },
    get: () => localStorage.getItem('button'),
    remove: () => localStorage.removeItem('button')
}

const getGenders = (idNumber) => {
    let res = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
    if (idNumber && res.test(idNumber)) {
        let genderCode = idNumber.charAt(16);
        if (parseInt(genderCode) % 2 == 0) {
            return '女';
        }
        return '男';
    } else {
        return '身份证格式输入有误！';
    }
}
const extractUrlParameter = (url) => {
    // 检查url是否为字符串，以及是否包含等号
    if (typeof url !== 'string' || url.indexOf('=') === -1) {
        console.log('未找到等号');
        return url;
    }

    // 获取最后一个等号的位置
    const lastEqualsPosition = url.lastIndexOf('=');
    // 截取等号后的内容
    const parameter = url.substring(lastEqualsPosition + 1);

    console.log('提取的参数:', parameter);
    return parameter;
}

export {
    tokenCookies,
    menuCookies,
    submenuCookies,
    buttonCookies,
    getGenders,
    extractUrlParameter
}
