import request from '@http';

// 登录
export function loginApi(params) {
    return request({
        method: 'POST',
        url: 'user/login',
        params,
    })
}

// 退出登录
export function logoutApi() {
    return request({
        method: 'POST',
        url: 'user/logout',
    })
}

// 用于防止session过期，30分钟调取一次接口，该接口无其它意义
export function connectApi() {
    return request({
        method: 'GET',
        url: 'api/user/record',
    })
}

// 2022/06/10 王江毅 获取登录用户权限
export function menuList() {
    return request({
        method: 'GET',
        url: '/api/gzf/system/menu/list',
    })
}
// 2023/09/21 王江毅 获取图片验证码
export function getHutoolVerifyCode() {
    return request({
        method: 'GET',
        url: '/hutool-verify-code',
        responseType: 'blob',
    })
}
// 2023/09/21 王江毅 获取短信验证码
export function getSmSVerifyCode(params) {
    return request({
        method: 'GET',
        url: '/send/login/verify/sms',
        params
    })
}