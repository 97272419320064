import request from '@http';

// 首页统计,获取小区数量
export function getCommunityTotalApi(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/baseinfo/chart/total',
        params,
    })
}

// 首页统计,获取收款情况
export function getReportGeneralApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/chart/report/receipt/general',
        params
    })
}

// 首页统计,获取小区动态
export function getCommunityApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/chart/page/comm',
       // url: '/api/gzf/baseinfo/chart/pagehome/community',
        params
    })
}

// 2022/05/30 王江毅 首页投诉
export function getFeedbackList(params) {
    return request({
        method: 'get',
        url: '/api/feedback/list',
        params
    })
}

// 2022/06/02 王江毅 首页维修
export function getRepairList(params) {
    return request({
        method: 'get',
        url: '/api/repair/list',
        params
    })
}

// 2022/06/02 王江毅 首页投诉 处理
export function feedbackDealFeed(data) {
    return request({
        method: 'post',
        url: '/api/feedback/dealFeed',
        data
    })
}

// 2022/06/08 王江毅 首页 运营情况查询
export function maintianFeedback(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/home/page/maintian/feedback',
        // url: '/api/gzf/baseinfo/chart/pagehome/communityRenta',

        params
    })
}

// 2022/06/08 王江毅 首页 上月退租情况查询
export function throwLease() {
    return request({
        method: 'GET',
        url: '/api/gzf/home/page/throw/lease',
    })
}

// 2022/06/08 王江毅 首页 上月退租情况查询
export function repairPubRepair(data) {
    return request({
        method: 'PUT',
        url: '/api/repair/pubRepair',
        data,
    })
}

// 2022/06/10 王江毅 首页 消息数量
export function outstandingCount(params) {
    return request({
        method: 'get',
        url: '/api/alerts/outstanding/count',
        params,
    })
}

// 2022/06/13 王江毅 消息列表 消息数量
export function alertsCount(params) {
    return request({
        method: 'get',
        url: '/api/alerts/count',
        params,
    })
}

// 2022/06/13 王江毅 首页 预约数量
export function homeCount() {
    return request({
        method: 'get',
        url: '/api/gzf/home/page/homeCount'
    })
}

// 2022/06/14 王江毅 首页 退换房消息数量
export function getHomeAlert(params) {
    return request({
        method: 'get',
        url: '/api/gzf/home/page/alert',
        params
    })
}

// 2022/09/07 王江毅 首页投诉 审核
export function authFeed(data) {
    return request({
        method: 'post',
        url: '/api/feedback/authFeed',
        data
    })
}

// 2022/11/28 王江毅 首页续租 小程序续租待审核列表数量
export function auditSize() {
    return request({
        method: 'get',
        url: '/api/gzf/contract/audit/size'
    })
}

// 2023/09/20 张晓瑜 首页维修 查询未完成数量
export function repairSizeApi() {
    return request({
        method: 'get',
        url: '/api/rent/repair/unfinished/quantity'
    })
}

// 2023/11/15 王江毅 获取退租信息
export function getHomeOverviewAPI(params) {
    return request({
        method: 'get',
        url: '/api/gzf/word/home/overview',
        params
    })
}

// 2023/11/17 王江毅 获取逾期租户信息
export function getHomeLateLeasorAPI(params) {
    return request({
        method: 'get',
        url: '/api/gzf/baseinfo/chart/pagehome/late/leasor',
        params
    });
}

// 2023/11/17 王江毅 获取合同到期信息
export function getHomeExpirationAPI(params) {
    return request({
        method: 'get',
        url: '/api/gzf/baseinfo/chart/pagehome/expiration/contract',
        params
    });
}

// 2023/11/21 王江毅 首页催收-批量催收
export function batchReminderAPI(data) {
    return request({
        method: 'post',
        url: '/api/gzf/ca/reminder/batch/reminder',
        data,
    });
}

// 2023/11/21 王江毅 获取退租角标数量
export function getOverviewCountAPI() {
    return request({
        method: 'get',
        url: '/api/gzf/word/home/overview/count'
    })
}


// 2024-2-21-陈守亮-退单
export function postrefundAPI(data) {
    return request({
        method: 'post',
        url: '/api/web/repair/chargeback',
        data
    })
}
