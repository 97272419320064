<template>
     <el-descriptions
             v-bind="$attrs"
             :size="size"
     >
          <template slot="title">
               <slot name="title"></slot>
          </template>
          <template slot="extra">
               <slot name="extra"></slot>
          </template>
          <descriptions-item
                  v-for="item in schemes"
                  :key="item.name"
                  :schema="item"
                  :data="data"
          >
          </descriptions-item>
     </el-descriptions>
</template>

<script>
    import DescriptionsItem from "./DescriptionsItem";

    export default {
        props: {
            data: Object,
            schemes: Array,
            size: {
                type: String,
                default: "small"
            },
        },
        name: "r-e-descriptions",
        components: { DescriptionsItem }
    };
</script>

<style scoped>

</style>
