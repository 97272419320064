<script>
    export default {
        name: "DescriptionsItem",
        props: {
            schema: Object,
            data: Object,
        },
        functional: true,
        render(h, ctx) {
            const { schema, data } = ctx.props;
          // console.log(data,schema,'909090')
            const { label, span, labelClassName, contentClassName, labelStyle, contentStyle, render } = schema;
            // alert(schema.name,'9999')
            const value = data[schema.name];
            // alert(value,'////')
            return h(
                "el-descriptions-item",
                {
                    props: {
                        label, span, labelClassName, contentClassName, labelStyle, contentStyle
                    }
                },
                render && typeof render === "function"
                    ?
                    [render(h, { value, data })]
                    :
                    value
            );
        }
    };
</script>

<style scoped>

</style>
