import router from "@/router";
import _ from "lodash";
import { tryHideFullScreenLoading } from "@custom/loading";
import { MessageError } from "@custom/message";

const messageErrorHint = _.debounce((error, message) => {
    let msg = message;

    tryHideFullScreenLoading();
    if (error && error.data && error.data.returnObject) {
        msg = error.data.returnObject.msg;
        // 登录失效，返回登录页
        if (error.data.resultCode === 0) router.push("/login");
    }
     if(msg !== '表号不存在'){
         if(msg !== null) MessageError(msg, 2000);
    }
}, 300);

export default messageErrorHint;

